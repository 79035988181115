import React, { useContext, useEffect, useState, createContext } from 'react'
import LIST_PRODUCTS from '../gql/queries/listProducts'
import { useLazyQuery, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { BottomTabNavigator } from '../components/BottomTabNavigator'
import { AppContext } from '../components/Layout'
import { useUserWindowScroll } from '../hooks/useUserWindowScroll'
import { RestaurantDetailsCard } from '../components/RestaurantDetailsCard'
import { ChooseDateAndMeal } from '../components/ChooseDateAndMeal'
import { FloatingFilters } from '../components/FloatingFilters'
import { BottomCart } from '../components/BottomCart'
import { MenuListingDesktop } from '../components/MenuListingDesktop'
import { Media } from '../media'
import { CustomisationModal } from '../components/CustomisationModal'
import { FoodListing } from '../components/FoodListing'
import { getOperationalHours, productTypeFilterHandler } from '../utils/functions'
import { EmptyList } from '../components/EmptyList'
import { ChatDesktop } from '../components/ChatDesktop'
import { AskCustomiseAgianModal } from '../components/AskCustomiseAgianModal'
import { DesktopAskCustomiseAgianModal } from '../components/DesktopAskCustomiseAgianModal'
import { FoodListDropDown } from '../components/FoodListDropDown'
import { FoodCard } from '../components/FoodCard'
import GET_PREVIOUSELY_ORDERED_PRODUCTS from '../gql/queries/getPreviouselyOrderedProducts'
import { HomePageProps } from '../ts/types/appContext.types'
import { CircularSpinner } from '../components/CircularSpinner'
import { API, withSSRContext } from 'aws-amplify'
import moment from 'moment'
import { SeachBar } from '../components/SeachBar'
import { getAppSettings, getProductList, getProductTypes, getTenantDetails } from '../lib/api'
import { FoodUnavailable } from '../components/FoodUnavailable'
import { LocationAlert } from '../components/LocationAlert'
import { useRouter } from 'next/router'
import { DateChangeConformation } from '../components/DateChangeConformation'
import { useDeviceSize } from '../hooks/useDeviceSize'
import { NotificationDesktop } from '../components/NotificationDesktop'
import { BackHandler } from '../components/BackHandler'
import { ProductSchedules } from '../ts/types/product.types'
import { PRODUCT } from '../ts/interfaces/product.interface'

export const HomePageContext = createContext<HomePageProps>(null!)

const HomePage = ({ listData, filteredData, themeSettings, isSubscriptionActive }: any) => {
  const {
    cartItems,
    location,
    setShowLocationHeader,
    openCustomisationModal,
    filterdProducts,
    setFilteredProducts,
    filterProps,
    mealDate,
    mealType,
    focusVar,
    setListProducts,
    setTickHandlerObject,
    showCustomisationScreen,
    setDesktopHeaderDetails,
    setSelectedTab,
    setLoginDetails,
    token,
    theme,
    profileData,
    previouslyOrderedFilterItems,
    setPreviouslyOrderedFilterItems,
    previouslyOrderedItems,
    setPreviouslyOrderedItems,
    tenant,
    setOpenFoodNotAvailable,
    unavailableItem,
    setUnavailableItem,
    setIsOrderCancelled,
    tenantOperationalHours,
    showNotification,
    timeZone,
    checkProduct
  } = useContext(AppContext)
  const isThresholdDistanceCrossed = useUserWindowScroll({ thresholdDistance: 10 })
  const [isChatOpen, setIsChatOpen] = useState<boolean>(true)
  const [displayNumber, setDisplayNumber] = useState<boolean>(true)
  const [data, setData] = useState(listData?.data ? listData.data : [])
  const [loading, setLoading] = useState(false)
  const [deskTopDateOpen, setDeskTopDateOpen] = useState(false)
  const [isCheckAvailabilityClicked, setCheckAvailabilityClicked] = useState(false)
  const date = new Date()
  const todaysDate = moment(date).format("DD MMM 'YY")
  const [filteredArray, setFilteredArray] = useState(filteredData)
  const [width] = useDeviceSize()
  const router = useRouter()
  const subActive = tenant?.isSubscriptionActive || isSubscriptionActive
  const {
    query: { fromLoginPage }
  } = router

  const [getProductsAuth, productAuth] = useLazyQuery(LIST_PRODUCTS)

  useEffect(() => {
    setData(productAuth?.data ? productAuth.data : [])
  }, [productAuth.data])

  const appSyncRequest = async (filterTime: {
    scheduleStartTime: string
    scheduleEndTime: string
    cutoffTime: string
  }) => {
    const data: any = await API.graphql({
      // @ts-ignore
      query: LIST_PRODUCTS,
      variables: {
        limit: 100,
        tenant: process.env.NEXT_PUBLIC_TENANT_ID,
        filter: filterTime
      },
      authMode: 'AWS_IAM'
    })
    setData(data?.data ? data.data : [])
    if (token !== '') {
      getProductsAuth({
        variables: {
          limit: 100,
          tenant: process.env.NEXT_PUBLIC_TENANT_ID,
          filter: filterTime
        },
        fetchPolicy: 'network-only'
      })
    }
    setLoading(false)
  }

  const dateCheckHandler = (data: any, checkDate: any) => {
    let flag = 0
    Object.values(data).map((item: any) => {
      if (item?.modified === checkDate) {
        flag = 1
        return
      }
    })
    if (flag === 1) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const choosenDate = getOperationalHours(mealDate)
    if (mealDate?.isoString !== '') {
      let datesArray: any = {}
      unavailableItem?.item?.productSchedules?.map((item: ProductSchedules, index: number) => {
        datesArray[index] = {
          actual: item?.scheduleStartTime,
          modified: moment(item?.scheduleStartTime).format('DD MMM')
        }
      })
      if (!dateCheckHandler(datesArray, moment(mealDate?.isoString).format('DD MMM'))) {
        let sortedDates = Object.values(datesArray).sort((a: any, b: any): any => {
          if (a.actual > b.actual) return 1
          if (a.actual < b.actual) return -1
          if (a.actual === b.actual) return 0
        })
        if (unavailableItem) {
          setUnavailableItem((prev: any) => ({ ...prev, datesArray: sortedDates }))
          setOpenFoodNotAvailable(true)
        }
      }
    }
    const scheduleStartTime =
      mealDate?.modifiedDate !== ''
        ? `${moment(mealDate?.isoString).format('YYYY-MM-DD')}${
            mealType?.type !== ''
              ? `T${mealType?.startTime}`
              : `T${tenantOperationalHours?.[choosenDate]?.openTime}`
          }`
        : ''
    const scheduleEndTime =
      mealDate?.modifiedDate !== ''
        ? `${moment(mealDate?.isoString).format('YYYY-MM-DD')}${
            mealType?.type !== ''
              ? `T${mealType?.endTime}`
              : `T${tenantOperationalHours?.[choosenDate]?.closeTime}`
          }`
        : ''

    const cutoffTime =
      mealDate?.modifiedDate !== ''
        ? `${moment().utcOffset(timeZone).format('YYYY-MM-DD')}${`T${moment()
            .utcOffset(timeZone)
            .format('HH:mm:ss')}.626Z`}`
        : ''
    let filterTime: any = {
      scheduleStartTime: scheduleStartTime !== '' ? scheduleStartTime : null,
      scheduleEndTime: scheduleEndTime !== '' ? scheduleEndTime : null,
      cutoffTime: cutoffTime !== '' ? cutoffTime : null
    }

    setLoading(true)
    setShowLocationHeader(true)
    appSyncRequest(filterTime)
  }, [mealDate, mealType, token])

  const { data: previouslyOrderedProducts } = useQuery(GET_PREVIOUSELY_ORDERED_PRODUCTS)

  const productTypeFilterHandlerFun = (productsData: PRODUCT[]) => {
    setListProducts(productsData)
    const filterdObject = productTypeFilterHandler(productsData)
    setFilteredArray({})
    setFilteredProducts(filterdObject)
  }

  const filterPropsHandler = (data: PRODUCT[]) => {
    let array: any = []
    data?.map((product: PRODUCT) => {
      if (filterProps?.category?.length > 0) {
        if (product?.productSchedules?.length > 0 || mealDate.isoString === '') {
          if (filterProps?.category?.includes(product?.dishType)) {
            array.push(product)
          }
        }
      } else {
        if (product?.productSchedules?.length > 0 || mealDate.isoString === '') {
          array.push(product)
        }
      }
    })
    return array
  }

  useEffect(() => {
    setShowLocationHeader(true)
    return () => setShowLocationHeader(false)
  }, [])

  useEffect(() => {
    if (profileData?.firstName) {
      setLoginDetails((prev) => ({ ...prev, userName: profileData.firstName }))
    }
  }, [profileData])

  useEffect(() => {
    if (openCustomisationModal) {
      document.body.classList.add('stop-scrolling')
    } else {
      document.body.classList.remove('stop-scrolling')
    }
  }, [openCustomisationModal])

  useEffect(() => {
    if (data?.listProducts?.products?.length) {
      const filteredData = filterPropsHandler(data?.listProducts?.products)
      setListProducts(filteredData)
      productTypeFilterHandlerFun(filteredData)
    }
    if (previouslyOrderedItems) {
      const filteredData = filterPropsHandler(previouslyOrderedItems)
      setPreviouslyOrderedFilterItems(filteredData)
    } else {
      setPreviouslyOrderedFilterItems(previouslyOrderedItems)
    }
  }, [filterProps, data, previouslyOrderedItems])

  useEffect(() => {
    if (!openCustomisationModal && !showCustomisationScreen) {
      setTickHandlerObject({})
    }
  }, [openCustomisationModal, showCustomisationScreen])

  useEffect(() => {
    if (previouslyOrderedProducts?.previouslyUniqueProductsOrdered?.length > 0) {
      let filteredArray: PRODUCT[] = []
      data?.listProducts?.products?.map((product: PRODUCT) => {
        if (previouslyOrderedProducts?.previouslyUniqueProductsOrdered?.includes(product?.id)) {
          filteredArray.push(product)
        }
      })
      setPreviouslyOrderedItems(filteredArray || [])
    }
  }, [previouslyOrderedProducts, data])

    const isKitchenInactive = () => !Boolean(tenant?.isActive)

  const focusHandler = () => {
    document.getElementById('home')?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    if (focusVar) {
      focusHandler()
    }
  }, [focusVar])

  useEffect(() => {
    setIsOrderCancelled(true)
    setDesktopHeaderDetails({
      secondaryDesktopHeader: false,
      showLocation: true,
      icon: '',
      title: '',
      profileHeader: false
    })
    setSelectedTab((prev: string[]): string[] => {
      let previousItems = [...prev]
      previousItems.map((tab: string, index: number) => {
        if (tab === 'Home') {
          previousItems.splice(index, 1)
        }
      })
      previousItems.push('Home')
      return previousItems
    })
  }, [])

  useEffect(() => {
    if (isCheckAvailabilityClicked) {
      // const element = document?.getElementById('desktop_homepage_scroll')
      // // element?.scrollTo({ top: 0 })
      // // window?.scrollTo({ top: 0 })
      setDeskTopDateOpen(true)
      setCheckAvailabilityClicked(false)
    }
  }, [isCheckAvailabilityClicked])

  return (
    <HomePageContext.Provider value={{ setCheckAvailabilityClicked }}>
      {subActive ? (
        <>
          <Media greaterThan="sm">
            <LocationAlert isUserDetailsFilled={fromLoginPage} />
            {loading && (
              <CircularSpinner
                className="m-auto flex h-screen items-center justify-center text-center"
                color={themeSettings?.customPrimary ?? theme?.customPrimary}
                size={65}
              />
            )}
            {!loading && (
              <div className={classNames('m-auto animate-opacity bg-[#F8F8F8]')}>
                <MenuListingDesktop
                  isKitchenInactive={isKitchenInactive()}
                  data={previouslyOrderedFilterItems}
                  filteredData={
                    Object.keys(filteredArray)?.length > 0 ? filteredArray : filterdProducts
                  }
                  {...{ deskTopDateOpen, setDeskTopDateOpen }}
                  product={router?.query?.product || checkProduct}
                />
                <CustomisationModal />
                <ChatDesktop
                  displayNumber={displayNumber}
                  isChatOpen={isChatOpen}
                  setDisplayNumber={setDisplayNumber}
                  setIsChatOpen={setIsChatOpen}
                />
                <DesktopAskCustomiseAgianModal />
                <FoodUnavailable />
                {showNotification && <NotificationDesktop />}
              </div>
            )}
            <BackHandler />
          </Media>
          <Media at="sm">
            <>
              {loading && (
                <CircularSpinner
                  className="m-auto flex h-screen items-center justify-center text-center"
                  color={themeSettings?.customPrimary ?? theme?.customPrimary}
                  size={35}
                />
              )}

              {!loading && (
                <div className="pb-[30px]">
                  <div
                    className={classNames(
                      'fixed z-30 w-[100%] transition-all duration-300',
                      isThresholdDistanceCrossed
                        ? 'transition-all duration-700'
                        : 'p-[20px] transition-all duration-300 ',
                      'fixed z-20 w-[100%] bg-[#F8F8F8]',
                      location?.secondary_text !== '' ? 'top-[62px]' : 'top-[38px]'
                    )}
                  >
                    <RestaurantDetailsCard
                      isThresholdDistanceCrossed={isThresholdDistanceCrossed}
                      tenant={tenant}
                    />
                  </div>
                  <div
                    className={classNames(
                      'bg-[#F8F8F8] transition-all duration-300',
                      isKitchenInactive() ? '' : 'pb-[40.4px]',
                      isThresholdDistanceCrossed
                        ? 'pt-[130px]'
                        : location?.secondary_text !== ''
                        ? 'pt-[235px]'
                        : 'pt-[190px]'
                    )}
                    id="home"
                  >
                    <div
                      className={classNames(
                        'px-[24px] pb-[16px]',
                        isThresholdDistanceCrossed ? '' : 'mt-[30px]'
                      )}
                    >
                      <ChooseDateAndMeal />
                    </div>
                    <div className="w-[100%] border-b-[1px] border-b-[#E5E5E5] bg-[white] px-[24px] py-[16px]">
                      <div className="">
                        <SeachBar />
                      </div>
                      <div className="flex w-[100%] bg-[white] pt-[16px]">
                        <FloatingFilters />
                      </div>
                    </div>
                    {isKitchenInactive() ? (
                      <EmptyList kitchenIsActive={isKitchenInactive()} />
                    ) : (
                      <div
                        className={classNames(
                          cartItems?.length > 0 ? 'pb-[176.51px]' : 'pb-[150px]'
                        )}
                      >
                        {previouslyOrderedFilterItems?.length > 0 && (
                          <>
                            <FoodListDropDown
                              header="Previously ordered"
                              numberOfItems={previouslyOrderedFilterItems?.length}
                              shadow={false}
                            >
                              {previouslyOrderedFilterItems?.map((product: PRODUCT) => (
                                <>
                                  <div className="px-[24px]" key={`previouslyOrdered${product.id}`}>
                                    <FoodCard
                                      foodDetails={product}
                                      {...{ setCheckAvailabilityClicked }}
                                    />
                                  </div>
                                  <div className="mx-[24px] border-[0px] border-b-[1px] border-solid border-b-[#E5E5E5]"></div>
                                </>
                              ))}
                            </FoodListDropDown>
                            <div className="mb-[8px]"></div>
                          </>
                        )}
                        {Object.keys(filteredArray)?.length > 0 ||
                        Object.keys(filterdProducts)?.length > 0 ? (
                          <FoodListing
                            filteredData={
                              Object.keys(filteredArray)?.length > 0
                                ? filteredArray
                                : filterdProducts
                            }
                            product={(router?.query?.product as string) || checkProduct}
                          />
                        ) : (
                          <EmptyList kitchenIsActive={isKitchenInactive()} />
                        )}
                      </div>
                    )}
                    <BottomTabNavigator />
                    <BottomCart />
                    {width < 768 && <AskCustomiseAgianModal />}
                    {width < 768 && <FoodUnavailable />}
                    {width < 768 && <DateChangeConformation />}
                  </div>
                </div>
              )}
            </>
            <BackHandler />
          </Media>
        </>
      ) : (
        <div className="mt-[300px] flex items-center justify-center">
          Kitchen is currently deactivated. Please check again later.
        </div>
      )}
    </HomePageContext.Provider>
  )
}

export async function getStaticProps({ req }: any) {
  const SSR = withSSRContext({ req })
  const listData: any = await getProductList(SSR)
  const filteredData = productTypeFilterHandler(listData?.data?.listProducts?.products)

  const tenantData = await getTenantDetails(SSR)
  const appData = await getAppSettings(SSR)

  const themeSettings = appData?.data?.getAppSettings[0]?.themeSettings
  const dishTypesData = await getProductTypes(SSR)
  const isSubscriptionActive = tenantData?.data?.getTenantDetails?.isSubscriptionActive
  return {
    props: {
      listData,
      filteredData,
      tenantData,
      dishTypesData,
      themeSettings,
      isSubscriptionActive
    }
  }
}

export default HomePage
