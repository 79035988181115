import classNames from 'classnames'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from './Layout'
import { DateChangeConformation } from './DateChangeConformation'
import { DesktopDatePicker } from './DesktopDatePicker'
import { DesktopMealPicker } from './DesktopMealPicker'
import { EmptyList } from './EmptyList'
import { FloatingFilters } from './FloatingFilters'
import { FoodCard } from './FoodCard'
import { FoodListDropDown } from './FoodListDropDown'
import { FoodListing } from './FoodListing'
import { SeachBar } from './SeachBar'
import { YourCart } from './YourCart'
import { PRODUCT } from '../ts/interfaces/product.interface'
import { useQuery } from '@apollo/client'
import GET_TENANT_DETAILS from '../gql/queries/getTenantDetails'

export const MenuListingDesktop = ({
  isKitchenInactive,
  data,
  deskTopDateOpen,
  setDeskTopDateOpen,
  filteredData,
  product
}: any) => {
  const { setFocusVar, mealDate, theme,mealType } = useContext(AppContext)
  const [height, setHeight] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState<any>(
    data?.length > 0 ? 'Previously ordered' : Object.keys(filteredData)?.[0]
  )

  const date = new Date()
  const todaysDate = moment(date).format("DD MMM 'YY")

  useEffect(() => {
    setSelectedCategory(data?.length > 0 ? 'Previously ordered' : Object.keys(filteredData)?.[0])
  }, [filteredData, data])

  const focusHandler = (menu: string) => {
    const element = document.getElementById(menu)
    const elementPosition = element?.getBoundingClientRect().top ?? 0
    const offsetPosition = elementPosition + window.pageYOffset - 100

    element?.scrollIntoView(true)
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
    setFocusVar(false)
  }

  useEffect(() => {
    if (data?.length) {
      const prevElement = document.getElementById('previous')
      const elementPosition = prevElement?.offsetHeight || 0
      setHeight(elementPosition)
    }
  }, [data])
  const { data: tenantDetails } = useQuery(GET_TENANT_DETAILS, {
    variables: {
      limit: 1000,
      tenantId: process.env.NEXT_PUBLIC_TENANT_ID
    },
    fetchPolicy: 'network-only'
  })
  const kitchenIsActive=tenantDetails?.getTenantDetails?.isActive;
  return (
    <div className="flex w-[100%] animate-opacity justify-between px-[30px] lg:px-[64px]">
      <div className="fixed mt-[83px] w-[20%]">
        {!(!kitchenIsActive && Boolean(mealDate.isoString || mealType.startTime))&& (
          <div>
            {data?.length > 0 && (
              <div
                className={classNames(
                  'mb-[20px] cursor-pointer text-[18px] font-medium leading-[22px] tracking-[-0.408px]',
                  selectedCategory === 'Previously ordered'
                    ? 'border-b-[2px] font-black font-[500]'
                    : 'font-book'
                )}
                style={{
                  borderBottomColor:
                    selectedCategory === 'Previously ordered' ? theme.customPrimary : '',
                  color: selectedCategory === 'Previously ordered' ? theme.customPrimary : ''
                }}
                onClick={() => {
                  setSelectedCategory('Previously ordered')
                  focusHandler('Previously ordered')
                }}
              >
                Previously ordered
              </div>
            )}

            {Object.keys(filteredData)?.map((Menu) => (
              <div
                className={classNames(
                  'mb-[20px] cursor-pointer text-[18px] font-medium leading-[22px] tracking-[-0.408px]',
                  selectedCategory === Menu ? 'border-b-[2px] font-black font-[500]' : 'font-book'
                )}
                style={{
                  borderBottomColor: selectedCategory === Menu ? theme?.customPrimary : '',
                  color: selectedCategory === Menu ? theme?.customPrimary : ''
                }}
                onClick={() => {
                  setSelectedCategory(Menu)
                  focusHandler(Menu)
                }}
                key={Menu}
              >
                {Menu}
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className="scroll absolute left-[25%] right-[25%] mx-[32px] pt-[19px] pb-[17px]"
        id={'desktop_homepage_scroll'}
      >
        <div>
          <div className="flex w-[100%]">
            <div className="mr-[5px] w-[50%] cursor-pointer">
              <DesktopDatePicker {...{ isOpen: deskTopDateOpen, setIsOpen: setDeskTopDateOpen }} />
            </div>
            <div className="ml-[5px] w-[50%] cursor-pointer">
              <DesktopMealPicker />
            </div>
          </div>
          <div className="mt-[17px] w-[100%] bg-[white]">
            <div className="border-b-[1px] border-b-[#E5E5E5] px-[24px]">
              <div className="w-[100%] py-[16px]">
                <SeachBar />
              </div>
              <div className="flex w-[100%] pb-[16px]">
                <FloatingFilters />
              </div>
            </div>

            {!kitchenIsActive && Boolean(mealDate.isoString || mealType.startTime) ? (
              <EmptyList kitchenIsActive={kitchenIsActive}/>
            ) : (
              <>
                {data?.length > 0 && (
                  <div id="previous">
                    <FoodListDropDown
                      header="Previously ordered"
                      numberOfItems={data?.length}
                      shadow={false}
                      key={`Previously ordered`}
                    >
                      {data?.map((product: PRODUCT) => (
                        <>
                          <div className="px-[24px]" key={`previouslyOrdered+${product.id}`}>
                            <FoodCard foodDetails={product} />
                          </div>
                          <div className="mx-[24px] border-[0px] border-b-[1px] border-solid border-b-[#E5E5E5]"></div>
                        </>
                      ))}
                    </FoodListDropDown>
                  </div>
                )}
                {Object.keys(filteredData)?.length > 0 ? (
                  <FoodListing
                    forDesktop={true}
                    filteredData={filteredData}
                    product={product}
                    height={height}
                  />
                ) : (
                  <EmptyList  kitchenIsActive={kitchenIsActive}/>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="fixed right-[30px] left-[75%] mt-[83px] lg:right-[64px]">
        <YourCart />
      </div>
      <DateChangeConformation />
    </div>
  )
}
